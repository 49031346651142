<div class="header" [ngClass]="{ on: showHeader && state == 'portal2-state' }">
  <div class="logo-home">
    <a href="https://www.takeda.com/">
      <img class="header-logo" src="../assets/images/takeda_logo.png" />
    </a>
  </div>
  <div class="research-state header-state on">
    <ng-container *ngFor="let hotspot of currentPhotosphere.hotspots">
      <ng-container *ngIf="!hotspot.disabled">
        <div
          *ngIf="hotspot.menuItem"
          class="header-item large"
          [class.on]="
            modalBelongToMenuItem(
              currentModal?.id,
              getHotspotData(hotspot.dataId)?.modal
            ) ||
            modalChildOf(
              currentModal?.id,
              getHotspotData(hotspot.dataId)?.modal
            )
          "
          (click)="setModalById(hotspot.dataId, hotspot)"
        >
          <span [innerHTML]="hotspot.menuItem"></span>
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>
<div class="header-toast" *ngIf="toastText && isModalOpen" [innerHTML]="toastText"></div>

