import { Component, Input } from '@angular/core';

@Component({
  selector: 'svg-arrows',
  templateUrl: './svg-arrows.component.svg'
})


export class SvgArrowsComponent {

    @Input() fillColor: string = "#000";
    
}