import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import Photospheres from '../../assets/data/base/photospheres.base.json';
import { Photosphere } from '../models';
import { GoogleService } from '../services/google-analytics.service';
import * as GTM from '../utils/GTM';

@Injectable({
  providedIn: 'root'
})
export class PhotosphereService {
  public rotation: BehaviorSubject<number> = new BehaviorSubject(0);
  public manualRotation: BehaviorSubject<number> = new BehaviorSubject(0);
  public currentPhotosphere: BehaviorSubject<any> = new BehaviorSubject(Photospheres[0]);
  public interactable = new BehaviorSubject(false);
  public currentRotation: BehaviorSubject<number> = new BehaviorSubject(0);
  public photosphereLoaded: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public blurred: BehaviorSubject<boolean> = new BehaviorSubject(true);

  constructor(
    private googleSvc: GoogleService,
    private router: Router
  ) { }

  setInteractable(val: boolean): void {
    this.interactable.next(val);
  }

  openChat(): void {
    if (((document.querySelector('#chat_now_button') as HTMLElement).style.display !== 'none')) {
      window['Twilio'].FlexWebChat.Actions.invokeAction('ToggleChatVisibility');
    } else {
      window['twilioChat'].showMRF();
      this.googleSvc.sendGAEventById(10);
      // GTM.sendGTMEvent(10);
    }
  }

  public setCurrentPhotosphere(id: string, rotation: number = undefined, setRoute: boolean = false, sendGA: boolean = true): void {

    if (id === this.currentPhotosphere.value.id) {
      //console.log(rotation, "is the rotation!");
      if (rotation) {
        this.manualRotation.next(rotation);
      }
      return;
    }

    console.log(id, rotation, setRoute, sendGA);

    const data: Photosphere = Photospheres.find(ps => ps.id === id);

    if (data) {
      this.currentPhotosphere.next(data);

      if (sendGA) {
        this.googleSvc.sendGAEventById(53, { category: data.name, label: data.url });
      }

      if (rotation) {
        this.rotation.next(rotation);
      } else {
        this.rotation.next(data.rotationParams.startX ? data.rotationParams.startX : 180);
      }

      // __TODO see if we need to add urls
      if (setRoute && data.url) {
        this.router.navigateByUrl(data.url);
      }

      this.googleSvc.setCurrentPhotosphere(data);
      
    }
  }
}
