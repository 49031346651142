import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-medical-information-request-form',
  templateUrl: './medical-information-request-form.component.html',
  styleUrls: ['./medical-information-request-form.component.scss'],
})
export class MedicalInformationRequestFormComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
