

/*
* Send Google Tag Manager Events
*/


export function checkTime(id: any, gtmObject: boolean = false): void {

}


export function check_time(currentTimeStr, timeEntry): boolean {
    let current_time = this.iso8601_to_unixtime(currentTimeStr);
    let range_start = this.iso8601_to_unixtime(timeEntry.start);
    let range_end = this.iso8601_to_unixtime(timeEntry.end);
    return current_time >= range_start && current_time <= range_end;
}

export function check_time_after_start(currentTimeStr, timeEntry): boolean {
    let current_time = this.iso8601_to_unixtime(currentTimeStr);
    let range_start = this.iso8601_to_unixtime(timeEntry.start);
    return current_time >= range_start;
}
export function check_time_after_end(currentTimeStr, timeEntry): boolean {
    let current_time = this.iso8601_to_unixtime(currentTimeStr);
    let range_end = this.iso8601_to_unixtime(timeEntry.end);
    return current_time >= range_end;
}

export function iso8601_to_unixtime(str) {
    const re = /(\d{4})-([01]\d)-([0-3]\d)T([0-2]\d):([0-5]\d):([0-5]\d)\.\d+([+-][0-2]\d:[0-5]\d|Z)/;
    const m = str.match(re);
    if (m) {
        let d = new Date();
        d.setFullYear(m[1]);
        d.setMonth(m[2]);
        d.setDate(m[3]);
        d.setHours(m[4]);
        d.setMinutes(m[5]);
        return d.getTime();
    }
    return 0;
}


