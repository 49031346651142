import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { Routes, RouterModule } from '@angular/router';
import Modals from '../assets/data/base/modals.base.json';
import Photospheres from '../assets/data/base/photospheres.base.json';

const routes: Routes = [
  { path: '', component: AppComponent, pathMatch: 'full' },
  { path: ':content', component: AppComponent },
  { path: 'welcome', component: AppComponent },
  { path: '', redirectTo: '/', pathMatch: 'full' }
];

let allPaths = [];
Modals.forEach((el: any) => {
  if (el.url && !allPaths.includes(el.url)) {
    allPaths.push(el.url);
  }
});

Photospheres.forEach((el: any) => {
  if (el.url && !allPaths.includes(el.url)) {
    allPaths.push(el.url);
  }
});

allPaths = allPaths.filter(el => {
  return el !== '/';
});

allPaths.forEach(el => {
  const path = el.replace('/', '');

  routes.push({ path: path, component: AppComponent, pathMatch: 'full' });
  routes.push({ path: `${path}/:content`, component: AppComponent, pathMatch: 'full' });
  routes.push({ path: `${path}/:content/:content`, component: AppComponent, pathMatch: 'full' });
});

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
