<div class="video-menu">
    <h2 class="title" [innerHTML]="data?.title"></h2>

    <div class="video-links">
        <div class="inner" [ngClass]="'w-' + data?.videos.length">
            <div *ngFor="let link of data?.videos" class="video-link" [ngClass]="{'large': link.watchNext}"
                (click)="openVideo(link)">
                <div class="img-container">
                    <img [src]="path + link.poster" [alt]="link.title" class="img">
                    <div class="play-btn" [ngClass]="[link.watchNext ? 'lg' : 'sm']"></div>
                </div>
                <p class="text" [innerHTML]="link.title"></p>
            </div>
        </div>
    </div>
</div>