<div class="quiz-modal">
  <form novalidate (ngSubmit)="submitAnswer()">
    <div class="quiz-modal-inner">
      <div class="header">
        <div class="title">test your knowledge</div>
        <div *ngIf="!isQuizCompleted" class="questions-counter">
          Question {{ currentQuestionNumber }} of {{ MAX_QUESTIONS }}
        </div>
      </div>
      <div class="body">
        <div *ngIf="!isQuizCompleted && !answerSubmitted" class="question-form">
          <div class="question">
            {{ currentQuestion.text }}
          </div>
          <div class="select-answer-label">Select your answer below</div>
          <div class="answers">
            <ul>
              <li *ngFor="let answer of currentQuestion.answers">
                <app-radio
                  [label]="answer.text"
                  [value]="answer"
                  [(ngModel)]="currentAnswer"
                  name="answer"
                ></app-radio>
              </li>
            </ul>
          </div>
        </div>
        <div
          *ngIf="!isQuizCompleted && answerSubmitted"
          class="question-result"
        >
          <div class="question">
            {{ currentAnswer.is_correct ? "Correct" : "Incorrect" }}
          </div>
          <app-result-text
            [html]="currentQuestion.result_text"
            (onLinkClick)="onResultTextLinkClick($event)"
          ></app-result-text>
          <div
            class="references"
            [innerHTML]="currentQuestion.references"
          ></div>
        </div>
        <div *ngIf="isQuizCompleted" class="quiz-summary">
          <div class="question">
            You scored <strong>{{ correctAnswersCount }}</strong> out of
            <strong>{{ MAX_QUESTIONS }}</strong
            >!
          </div>
          <app-result-text
            [html]="quiz.end_result"
            (onLinkClick)="onResultTextLinkClick($event)"
          ></app-result-text>
        </div>
      </div>
      <div class="buttons">
        <button
          *ngIf="!isQuizCompleted && !answerSubmitted"
          class="btn submit-btn"
          type="submit"
        >
          submit
        </button>
        <button
          *ngIf="!isQuizCompleted && answerSubmitted"
          class="btn continue-btn"
          type="button"
          (click)="continue()"
        >
          continue
        </button>
      </div>
    </div>
  </form>
  <div class="veeva-code">{{ quiz.veeva_code }}</div>
</div>
