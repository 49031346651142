import { environment } from '../../environments/environment';
import { Injectable } from '@angular/core';

@Injectable()
export class UtilityService{

    /**
     * Get configured contentUrl per environment.contentUrlRules
     * @returns string Any detected contentUrl. No trailing slash. Default is "/".
     */
    getContentUrl():string {
        let contentUrl = "";
        if (environment.contentUrlRules && environment.contentUrlRules.length) {
            console.log(window.location.hostname, "is the window.location.hostname");
            environment.contentUrlRules.forEach((rule: any) => {
                if (rule.targetHosts.includes(window.location.hostname)) {
                    contentUrl = rule.contentUrl;
                }
                console.log(rule, contentUrl, "is the rule and detected contentUrl");
            });
        }
        return contentUrl;
    }

    /**
     * Expands $contentUrl variable within a string to the current environment's contentUrl
     * @param s string
     * @returns string The modified string
     */
    expandContentUrl(s:string):string {
        if (s.indexOf('$contentUrl') > -1) {
            return s.replace('$contentUrl', this.getContentUrl());
        } else {
            return s;
        }
    }

}