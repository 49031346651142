import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ModalService } from '../../services';
import { DomSanitizer } from '@angular/platform-browser';
import * as Utils from '../../utils/Utils';


@Component({
  selector: 'app-live-stream',
  templateUrl: './live-stream.component.html',
  styleUrls: ['./live-stream.component.scss']
})
export class LiveStreamComponent implements OnInit {
  @Input() data: any;
  public streamAvailable: boolean = false;
  public iframeSource: any;
  public iframeCountDown: any;
  public endedSource: any;
  public ended: boolean = false;
  public speaker: any = 'test';
  public speakerFeeds: any = [
    {
      name: 'test', url: 'https://sapphire.maestro.io/nmrevents/home?embed=theater', countdown: 'https://cdn.houdini.studio/storage/envyvio/feagan', ended: 'https://cdn.houdini.studio/storage/envyvio/ended'
    },
    {
      name: 'brian_feagan', url: 'https://sapphire.maestro.io/nmrevents', countdown: 'https://cdn.houdini.studio/storage/envyvio/feagan', ended: 'https://cdn.houdini.studio/storage/envyvio/ended'
    },
    {
      name: 'brian_bressler', url: 'https://sapphire.maestro.io/nmrevents', countdown: 'https://cdn.houdini.studio/storage/envyvio/bressler', ended: 'https://cdn.houdini.studio/storage/envyvio/ended'
    }];
  // Test live stream
  // ''

  public liveStreamTimesTest: any = {
    start: '2020-10-17T12:00:00.000000-04:00',
    end: '2020-10-17T17:54:00.000000-04:00'
  };
  // public liveStreamTimesFeagan: any = {
  //   start: '2020-10-26T14:48:00.000000-04:00',
  //   end: '2020-10-26T16:22:00.000000-04:00'
  // };
  public liveStreamTimesFeagan: any = {
    start: '2020-10-26T16:30:00.000000-04:00',
    end: '2020-10-26T19:00:00.000000-04:00'
  };
  public liveStreamTimesBressler: any = {
    start: '2020-10-28T15:00:00.000000-04:00',
    end: '2020-10-28T18:00.000000-04:00'
  };

  public liveStreamTimes: any = [
    {
      name: 'test', times: this.liveStreamTimesTest
    },
    {
      name: 'brian_feagan', times: this.liveStreamTimesFeagan
    },
    {
      name: 'brian_bressler', times: this.liveStreamTimesBressler
    }
  ];


  constructor(private http: HttpClient, private modalSvc: ModalService, private _sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    // this.iframeSource = this._sanitizer.bypassSecurityTrustResourceUrl('https://stage.houdini.studio/');
    // this.iframeCountDown = this._sanitizer.bypassSecurityTrustResourceUrl('https://stage.houdini.studio/');
    this.detect_chat_time();
  }
  public ngOnChanges(): void {
    if (this.data.speaker) {
      console.log('this.data.speaker', this.data.speaker);
      const speaks = this.speakerFeeds.find(d => d.name === this.data.speaker);
      if (speaks) {
        // console.log('speaks', speaks);
        this.iframeSource = this._sanitizer.bypassSecurityTrustResourceUrl(speaks.url);
        this.iframeCountDown = this._sanitizer.bypassSecurityTrustResourceUrl(speaks.countdown);
        this.endedSource = this._sanitizer.bypassSecurityTrustResourceUrl(speaks.ended);
        this.detect_chat_time();
        // console.log('this.iframeCountDown', this.iframeCountDown);
      } else {
        this.iframeSource = this._sanitizer.bypassSecurityTrustResourceUrl('');
      }
    } else {
      this.iframeSource = this._sanitizer.bypassSecurityTrustResourceUrl('');
      this.speaker = 'test';
    }

  }

  public detect_chat_time(): void {

    this.http.get<any>('https://worldtimeapi.org/api/timezone/America/New_York').subscribe(res => {
      console.log(res);

      const times = this.liveStreamTimes.find(d => d.name === this.data.speaker);
      console.log('times', times);
      if (times) {
        let inRange = false;

        // console.log('CHECK IT : ', (Utils.check_time(res.datzetime, times.times)));
        if (Utils.check_time(res.datetime, times.times)) {
          inRange = true;
        }

        if (Utils.check_time_after_start(res.datetime, times.times)) {
          if (Utils.check_time_after_end(res.datetime, times.times)) {
            inRange = false;
            this.ended = true;
          }
        }
        this.streamAvailable = inRange;
      } else {
        // Tony Conti: Debug.
        this.streamAvailable = false;
      }
    });

  }


}
