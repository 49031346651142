import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import Tracking from '../../assets/data/gtmEvents.json';
import { TrackingObject } from '../models';
import Photospheres from '../../assets/data/base/photospheres.base.json';
import { Photosphere, Hotspot } from '../models';
import { BehaviorSubject } from 'rxjs';

declare const gtag: Function;
const dataLayer = window['dataLayer'];
//console.log(dataLayer, "is the dataLayer #2");
const prod =
  !(window.location.host.indexOf('localhost') > -1) &&
  !(window.location.host.indexOf('stage') > -1);
//const DEBUG = window.location.hostname === "localhost" || window.location.hostname.includes("isth-stage");
const DEBUG = window.location.hostname === "localhost";

@Injectable({
  providedIn: 'root',
})
export class GoogleService {

  public currentPhotosphere: BehaviorSubject<any> = new BehaviorSubject(Photospheres[0]);

  constructor(
    private router: Router
  ) {
    /*
    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        console.log('##### Trigger a page_view');
        this.sendPageView();
      }
    });
    */
  }

  ngOnInit(): void {

  }

  public setCurrentPhotosphere(data: Photosphere) {
    this.currentPhotosphere.next(data);
  }

  public sendPageView(): void {
    let theEvent = {
      event: 'page_view',
      page_title: document.title,
      page_location: window.location.href,
      page_path: window.location.pathname,
    };
    console.log(theEvent, '##### page view event');
    if (!DEBUG) {
      if (typeof gtag !== "undefined") {
        gtag('event', theEvent.event, theEvent);
      } else if (dataLayer) {
        dataLayer.push(theEvent);
      }
    }
  }

  public sendGAEventById(id: any, overrides: any = undefined): void {
    let event: TrackingObject = Tracking.find((el) => el.id === id);
    if (!event) {
      console.log(`No tracking data found for id: ${id}`);
      return;
    } else {
      if (overrides) {
        event = Object.assign(event, overrides);
      }
    }
    this.sendGAEventByData(event);
  }

  public sendGAEventByData(event: any): void {
    if (!event) {
      console.log('No tracking data found');
      return;
    }
    // strip out html from category/label
    let tmp;
    tmp = document.createElement('div');
    tmp.innerHTML = event.eventLabel;
    event.eventLabel = tmp.textContent || tmp.innerText || '';
    tmp = document.createElement('div');
    tmp.innerHTML = event.eventCategory;
    event.eventCategory = tmp.textContent || tmp.innerText || '';
    // Build and send the event
    const theEvent = {
      event: event.event,
      eventCategory: this.formatEventProperty(event.eventCategory),
      eventAction: this.formatEventProperty(event.eventAction),
      eventLabel: this.formatEventProperty(event.eventLabel),
    };
    if (event.eventValue) {
      theEvent['event_value'] = event.eventValue;
    }
    console.log(theEvent, 'GTM send event');
    if (!DEBUG) {
      if (typeof gtag !== "undefined") {
        gtag('event', theEvent.event, theEvent);
      } else if (dataLayer) {
        dataLayer.push(theEvent);
      }
    }
  }

  protected formatEventProperty(prop: any) {
    if (typeof prop === "undefined") {
      return "";
    }
    if (prop === "undefined") {
      return "";
    }
    return prop;
  }

  public sendHotspotEvent(hotspot: Hotspot) {
    console.log(this.currentPhotosphere.value, hotspot, "is the current photosphere and hotspot");
    if (this.currentPhotosphere.value && hotspot) {
      let title = hotspot.gtmTitle;
      if (!title && hotspot.menuItem) {
        title = hotspot.menuItem.replace(/<[^>]+>/g, " ");
      }
      if (title) {
        console.log("SENDING EVENT");
        this.sendGAEventByData({
          "event": "hotspot",
          "eventCategory": this.currentPhotosphere.value.name,
          "eventAction": title,
          "eventLabel": ""
        });
      }
    }
  }

  public sendMenuHotspotEvent(hotspot: any) {
    console.log(this.currentPhotosphere.value, hotspot, "is the current photosphere and hotspot");
    if (this.currentPhotosphere.value && hotspot) {
      let title = hotspot.gtmTitle;
      if (!title && hotspot.menuItem) {
        title = hotspot.menuItem.replace(/<[^>]+>/g, " ");
      }
      if (title) {
        console.log("SENDING EVENT");
        this.sendGAEventByData({
          "event": "hotspot",
          "eventCategory": this.currentPhotosphere.value.name,
          "eventAction": title,
          "eventLabel": ""
        });
      }
    }
  }

  public sendExternalLinkEvent(title: string, url: string) {
    this.sendGAEventByData({
      "event": "outboundLink",
      "eventCategory": title,
      "eventAction": url,
      "eventLabel": ""
    });
  }

  public sendCustomDataEvent(event: string, data: any) {
    data['event'] = event;
    dataLayer.push(data);
  }

  private getShowName(): string {
    return '';
    // return localStorage.getItem(`${environment.showName}-show-name`) || '';
  }
}
