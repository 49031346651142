<div
  class="video-player"
  [ngClass]="[
    singleVideo ? 'single' : '',
    download_link ? 'with-download' : '',
    with_play_btn ? 'with-play' : ''
  ]"
>
  <div class="video-container">
    <video
      id="video-player"
      [src]="(activeVideo?.src.indexOf('http://') === 0 || activeVideo?.src.indexOf('https://') === 0) ? activeVideo?.src : (path + activeVideo?.src)"
      [poster]="(activeVideo?.poster.indexOf('http://') === 0 || activeVideo?.poster.indexOf('https://') === 0) ? activeVideo?.poster : (path + activeVideo?.poster)"
      autoplay
      controls
      controlsList="nodownload noplaybackrate"
      disablePictureInPicture
      oncontextmenu="return false;"
      preload="auto"
    >
      <source [src]="path + activeVideo?.path" type="video/mp4" />
    </video>

    <div *ngIf="!videoPlaying" class="play-btn xl" (click)="playVideo()"></div>

    <a
      *ngIf="download_link"
      (click)="downloadClicked($event, download_link)"
      href="#"
      class="video-download"
      target="_blank"
    >
      <span [innerHTML]="download_link.text"></span>
      <img src="../../assets/images/download.svg" [alt]="download_link.text" />
    </a>
  </div>

  <div class="footer" *ngIf="videoFooterEnabled">
    <h2 class="title" [innerHTML]="activeVideo?.title"></h2>

    <div class="right" *ngIf="nextVideo">
      <div class="text">
        <p [innerHTML]="nextVideo.title"></p>
      </div>

      <div class="up-next pill-btn" (click)="nextVideoClick()">
        <span>Up Next</span>
        <div class="arrow">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16.872"
            height="22.047"
            viewBox="0 0 16.872 22.047"
          >
            <path
              d="M3246.694,958.889v-.031l-16.872,11.023,16.872,11.023v-.031"
              transform="translate(3246.694 980.905) rotate(180)"
            />
          </svg>
        </div>
      </div>
    </div>
  </div>
</div>
