import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import {
  PhotosphereComponent,
  ModalComponent,
  SideMenuComponent,
  SvgArrowsComponent,
  HeaderComponent,
  FooterComponent,
  WelcomeComponent,
  VideoMenuComponent,
  VideoPlayerComponent,
  LandingComponent,
  SlideshowComponent,
  LiveStreamComponent,
} from './components';

import { NumberSuffixPipe } from './pipes';

import { PdfViewerModule } from 'ng2-pdf-viewer';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';

import { AppRoutingModule } from './app-routing.module';
import { MedicalInformationRequestFormComponent } from './components/forms/medical-information-request-form/medical-information-request-form.component';
import { DynamicComponentsLoaderComponent } from './components/dynamic-components-loader/dynamic-components-loader.component';
import { ViewContainerHostDirective } from './directive/view-container-host.directive';
import { QuizComponent } from './components/quiz/quiz.component';
import { RadioComponent } from './components/radio/radio.component';
import { FormsModule } from '@angular/forms';
import { ResultTextComponent } from './components/quiz/result-text/result-text.component';
import { RedirectToExternalLinkModalComponent } from './components/redirect-to-external-link-modal/redirect-to-external-link-modal.component';
import { CommonModule } from '@angular/common';

import { UtilityService } from './services/utility.service';

@NgModule({
  declarations: [
    AppComponent,
    PhotosphereComponent,
    ModalComponent,
    SideMenuComponent,
    SvgArrowsComponent,
    HeaderComponent,
    FooterComponent,
    WelcomeComponent,
    VideoMenuComponent,
    VideoPlayerComponent,
    NumberSuffixPipe,
    LandingComponent,
    SlideshowComponent,
    LiveStreamComponent,
    MedicalInformationRequestFormComponent,
    DynamicComponentsLoaderComponent,
    ViewContainerHostDirective,
    QuizComponent,
    RadioComponent,
    ResultTextComponent,
    RedirectToExternalLinkModalComponent,
  ],
  imports: [
    BrowserModule,
    CommonModule,
    FormsModule,
    PdfViewerModule,
    PdfJsViewerModule,
    RouterModule.forRoot([]),
    HttpClientModule,
    AppRoutingModule,
  ],
  providers: [
    { provide: Window, useValue: window },
    UtilityService
  ],
  entryComponents: [MedicalInformationRequestFormComponent, QuizComponent],
  bootstrap: [AppComponent],
})
export class AppModule {}
