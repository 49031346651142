import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Modal } from '../../models';
import { ModalService, GoogleService } from '../../services';

import Modals from '../../../assets/data/base/modals.base.json';

export interface QuizAnswer {
  text: string;
  is_correct: boolean;
}

export interface QuizQuestion {
  text: string;
  result_text: string;
  answers: QuizAnswer[];
  references: string;
}

export interface QuizModel {
  questions: QuizQuestion[];
  end_result: string;
  veeva_code: string;
  title?: string;
}

@Component({
  selector: 'app-quiz',
  templateUrl: './quiz.component.html',
  styleUrls: ['./quiz.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class QuizComponent implements OnInit {
  @Input()
  payload: { quizData: QuizModel };

  @Input()
  id: string;

  public MAX_QUESTIONS = 5;

  public quiz: QuizModel;

  private currentQuestionIndex: number = 0;

  private userAnswers: QuizAnswer[];

  private quizCompleted: boolean = false;

  public answerSubmitted: boolean = false;

  constructor(private modalSvc: ModalService, private googleSvc: GoogleService) {}

  ngOnInit(): void {
    this.quiz = this.payload.quizData;
    this.MAX_QUESTIONS = this.quiz.questions.length;

    const existingQuizState = this.loadQuizState();

    if (!existingQuizState) {
      this.userAnswers = new Array(this.MAX_QUESTIONS).fill(null);
    } else {
      this.userAnswers = existingQuizState.userAnswers;
      this.currentQuestionIndex = existingQuizState.currentQuestionIndex;
      this.quizCompleted = existingQuizState.quizCompleted;
      this.answerSubmitted = existingQuizState.answerSubmitted;
      this.clearQuizState();
    }
  }

  public get currentQuestion(): QuizQuestion {
    return this.quiz.questions[this.currentQuestionIndex];
  }

  public get currentQuestionNumber(): number {
    return this.currentQuestionIndex + 1;
  }

  public get currentAnswer(): QuizAnswer {
    return this.userAnswers[this.currentQuestionIndex];
  }

  public set currentAnswer(answer: QuizAnswer) {
    this.userAnswers[this.currentQuestionIndex] = answer;
  }

  public get isQuizCompleted(): boolean {
    return this.quizCompleted;
  }

  public get correctAnswersCount(): number {
    return this.userAnswers.filter((x) => x.is_correct).length;
  }

  public submitAnswer() {
    if (!this.currentAnswer) return;
    this.googleSvc.sendGAEventById("quiz-answer", {
      eventCategory: this.quiz.title + " - " + this.currentQuestion.text,
      //eventCategory: this.quiz.title,
      eventLabel: this.currentAnswer.text,
      eventValue: this.currentQuestionIndex + 1
    });
    this.answerSubmitted = true;
    console.log('Submitted answers', this.userAnswers);
  }

  public continue() {
    if (this.currentQuestionNumber < this.MAX_QUESTIONS) {
      this.currentQuestionIndex++;
    } else {
      this.quizCompleted = true;
    }

    this.answerSubmitted = false;
  }

  public onResultTextLinkClick(targetModalId: string) {
    this.saveQuizState();

    const data: Modal = {
      ...Modals.find((el) => el.id === targetModalId),
    } as Modal;

    data.parent = this.id;

    this.modalSvc.setActiveModalByData(data);
  }

  private saveQuizState() {
    this.lsSave(this.id + '_state', {
      userAnswers: this.userAnswers,
      currentQuestionIndex: this.currentQuestionIndex,
      quizCompleted: this.quizCompleted,
      answerSubmitted: this.answerSubmitted,
    });
  }

  private loadQuizState() {
    return this.lsLoad(this.id + '_state');
  }

  private clearQuizState() {
    this.lsClearKey(this.id + '_state');
  }

  private lsSave(key: string, data: any) {
    localStorage.setItem(key, JSON.stringify(data));
  }

  private lsLoad(key: string) {
    return JSON.parse(localStorage.getItem(key));
  }

  private lsClearKey(key: string) {
    localStorage.removeItem(key);
  }
}
