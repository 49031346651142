
import GTMEvents from '../../assets/data/gtmEvents.json';
import { environment } from 'src/environments/environment';

/*
* Send Google Tag Manager Events
*/
//declare const gtag: Function;
const gtag = window['gtag'];
console.log(gtag, "is the gtag #1");
const dataLayer = window['dataLayer'];
console.log(dataLayer, "is the dataLayer #1");
//const prod = (!(window.location.host.indexOf('localhost') > -1) && !(window.location.host.indexOf('stage') > -1));
const prod = true;

export function sendGTMEvent(id: any, gtmObject: boolean = false): void {
    let event = undefined;
    if (gtmObject) {
        event = id;
    } else {
        event = GTMEvents.find(ev => ev.id === id);
    }
    if (event && prod) {
        // strip out html from category/label
        let tmp;
        if (event.eventLabel) {
            tmp = document.createElement('div');
            tmp.innerHTML = event.eventLabel;
            event.eventLabel = tmp.textContent || tmp.innerText || '';
        }
        if (event.eventCategory) {
            tmp = document.createElement('div');
            tmp.innerHTML = event.eventCategory;
            event.eventCategory = tmp.textContent || tmp.innerText || '';
        }
        if (gtag) {
            gtag('event', event.event, event);
        } else {
            dataLayer.push(event);
        }
    }
    if (event) {
        console.log('sendGTMEvent', id, event);
    }
}

export function sendHCPEvent(bHCP: boolean, country: string = ''): void {
    // const event = {
    //     event: 'identification',
    //     eventCategory: (bHCP) ? 'Healthcare Professional' : 'Not Healthcare Professional'
    // };
    const event = {
        event: 'hcp_gate',
        hcp: (bHCP) ? 'yes' : 'no',
        country: country
    };
    console.log('sendHCPEvent', event);
    console.log(dataLayer, "is the dataLayer #2");
    if (prod) {
        if (gtag) {
            gtag('event', event.event, event);
        } else {
            dataLayer.push(event);
        }
    }
}


export function sendCustomDataEvent(event: string, data: any) {
    data['event'] = event;
    dataLayer.push(data);
}
