import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { ModalService } from '../../services';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-video-menu',
    templateUrl: './video-menu.component.html',
    styleUrls: ['./video-menu.component.scss']
})
export class VideoMenuComponent implements OnInit, OnChanges {
    @Input() data: any;
    public path: string = environment.assetBasePath + 'videos/';

    constructor(private modalSvc: ModalService) { }

    ngOnInit(): void {
    }

    ngOnChanges(): void {
    }

    public openVideo(link): void {
        const data = {
            active_video: link,
            id: link.id,
            all_videos: this.data.videos,
            modal_type: 'video-player',
            parent: this.data.id,
            gtm_id: link.gtm_id
        };
        this.modalSvc.modalData.next(data);
    }
}
