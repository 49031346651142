<div class="live-stream">

  <div class="stream-container">
    <ng-container *ngIf="streamAvailable">
      <div class="iframe-container">
        <iframe [src]="iframeSource" class="iframe"></iframe>
      </div>
    </ng-container>
    <div *ngIf="!streamAvailable && !ended">
      <div class="iframe-container">
        <iframe [src]="iframeCountDown" class="iframe"></iframe>
      </div>
    </div>
    <div *ngIf="ended">
      <div class="iframe-container">
        <iframe [src]="endedSource" class="iframe"></iframe>
      </div>
    </div>
  </div>


</div>