import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export enum SideMenuState {
  OPEN = 'open-state',
  CLOSED = 'closed-state',
}

@Injectable({
  providedIn: 'root',
})
export class SideMenuService {
  public sideMenuState: BehaviorSubject<string> = new BehaviorSubject('');

  constructor() {}

  public setSideMenuState(state: string): void {
    this.sideMenuState.next(state);
  }
}
