export const commonEnv = {
  assetBasePath: "",
  showName: "HAE",
  fov: 75,
  persistentMenu: false,
  menuButtonType: "default",
  menuTitle: "Product Portfolio",
  menuType: "default",
  menuTooltips: false,
  footerType: "default",
  disablePhotosphereRotate: true,
  showHomeLink: false,
  homeLinkPhotosphereId: "home",
  showExitLink: true,
  exitLinkType: "photosphere",
  exitLinkValue: "center",
  showMenuButtonWhenHeaderVisible: false,
  autoOpenMenuOnHome: false,
  welcomeSettings: {
    countryGate: true,
    rememberHcpGate: true,
    title: "Welcome to the Takeda<br />Medical Affairs Virtual Experience",
    disclaimer:
      "Content accessible in this virtual booth is intended for Healthcare Professionals (HCPs) and should</br>be accessed only by HCPs registered for EAACI 2023. By visiting the Takeda medical exhibit you</br>are confirming that you are a Healthcare Professional registered to attend this event.",
    showFooter: true,
    redirectNotice: "The information is intended for Healthcare Professionals.",
  },
  footerCopy:
    "<p class='footer-main'>This booth material is intended for healthcare professionals registered for the EAACI Hybrid Congress 2023.</p></br><p class='footer-small'>Copyright ©️ 2023 Takeda Pharmaceutical Company Limited.  All rights reserved.</br>Takeda and the Takeda logo are registered trademarks of Takeda Pharmaceutical Company Limited.</br>VV-MEDMAT-67472 05/2023 </p>",
  contentUrlRules: [
    {
      targetHosts: ["localhost"],
      contentUrl: "http://localhost:4200/assets/final-content",
    },
    {
      targetHosts: ["hae-stage.websitemagic.dev"],
      contentUrl: "https://hae-stage.websitemagic.dev/assets/final-content",
    },
    {
      targetHosts: ["localhost", "eaaci2023.haemedical.com"],
      contentUrl: "https://eaaci2023.haemedical.com/assets/final-content",
    },
  ],
};
