<div class="slideshow">
  <!-- <h2 class="title" [innerHTML]="data?.title"></h2> -->
  <!-- <h2 class="title" [innerHTML]="data?.title"> Slide Show</h2> -->

  <div class="video-links">
    <div class="inner">
      <!-- <div *ngFor="let link of data?.videos" class="video-link" [ngClass]="{'large': link.watchNext}" -->
      <div *ngFor="let slide of images; let i = index" class="slide" [ngClass]="{'show': i === currentSlide}">
        <div class="img-container">
          <img [src]="slide" [alt]="" class="img"
            [ngClass]="{'interactive': hitAreas.length > 0}"
            (click)="imageHotSpotClick($event)">
        </div>
      </div>

      <div class="arrow-button left" [ngClass]="{'show': currentSlide > 0}" (click)="prevSlide()">
        <svg xmlns="http://www.w3.org/2000/svg" width="37.5" height="37.5" viewBox="0 0 37.5 37.5">
          <path id="Path_512" data-name="Path 512"
            d="M1624.179,449.844h28.594l-13.125,13.125,3.281,3.281,18.75-18.75-18.75-18.75-3.281,3.282,13.125,13.125h-28.594Z"
            transform="translate(1661.679 466.25) rotate(180)" fill="#9d1d96" />
        </svg>
      </div>

      <div class="arrow-button right" [ngClass]="{'show': currentSlide < images.length-1}" (click)="nextSlide()">
        <svg xmlns="http://www.w3.org/2000/svg" width="37.5" height="37.5" viewBox="0 0 37.5 37.5">
          <path id="Path_512" data-name="Path 512"
            d="M1624.179,449.844h28.594l-13.125,13.125,3.281,3.281,18.75-18.75-18.75-18.75-3.281,3.282,13.125,13.125h-28.594Z"
            transform="translate(1661.679 466.25) rotate(180)" fill="#9d1d96" />
        </svg>
      </div>

      <div class="dots" *ngIf="images.length > 1">
        <a *ngFor="let slide of images; let i = index" href="#" class="dot" [ngClass]="{'active': i === currentSlide}" (click)="dotClick($event, i)"></a>
      </div>
    </div>
  </div>
</div>
