export enum SideMenuItemType {
  Text = 'text',
  Image = 'image',
}

export enum SideMenuItemTargetType {
  Photosphere = 'photosphere',
  Hotspot = 'hotspot',
  Modal = 'modal',
  ExternalLink = 'external-link',
  Chat = 'chat',
  MedForm = 'med-form',
}

export interface SideMenuItem {
  type: SideMenuItemType;
  text?: string;
  imageSrc?: string;
  cssClasses?: string;
  targetType: SideMenuItemTargetType;
  targetId?: string;
  visible: boolean;
  modalId?: string
}
