import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {
  public headerState: BehaviorSubject<string> = new BehaviorSubject('');
  // Tony Conti: This doesn't work...
  public headerIsOn: boolean = this.headerState.getValue() === 'home-state';
  constructor() { }

  public setHeaderState(data: any): void {
    this.headerState.next(data);
  }
}
