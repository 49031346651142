import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-result-text',
  templateUrl: './result-text.component.html',
  styleUrls: ['./result-text.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ResultTextComponent implements OnInit {
  private _html: any;

  @Input()
  set html(html_string: any) {
    this._html = this.sanitizer.bypassSecurityTrustHtml(html_string);
  }

  get html() {
    return this._html;
  }

  @Output()
  public onLinkClick: EventEmitter<string> = new EventEmitter<string>();

  constructor(private sanitizer: DomSanitizer) {}

  ngOnInit(): void {}

  public onTextClick(event) {
    if (event.target.className !== 'link') return;
    this.onLinkClick.emit(event.target.dataset.targetModal);
  }
}
